import {Journal} from "../types/transaction";
import {formatDate, formatNumber} from "./transactionUtils";

const convertJournalArrayToCSV = (journals: Journal[]): string => {
  const header = [
    'id',
    'valueDate',
    'peerName',
    'description',
    'amountValue',
    'amountCurrency',
    'account',
    'source',
  ].join(',');

  const rows = journals.map(journal => {
    return [
      'FIN-' + journal.id ?? '',
      journal.peerName,
      journal.description,
      formatDate(journal.valueDate),
      formatNumber(journal.amountValue),
      journal.amountCurrency,
      journal.account?.fullName ?? '',
      (journal.source?.key ?? '') + "-" + (journal.source?.name ?? ''),
    ].join(';');
  });

  return [header, ...rows].join('\n');
};

export const exportJournalsCSV = (journals: Journal[], filename: string = 'journals.csv') => {
  const csvData = convertJournalArrayToCSV(journals);
  const blob = new Blob([csvData], {type: 'text/csv;charset=utf-8;'});

  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
