import React, {createContext, useState} from 'react';
import {ConfigProvider, theme} from 'antd';
import {LayoutComponent} from "./components/Layout";
import client from "./apolloClient";
import {ApolloProvider} from "@apollo/client";


export const ThemeContext = createContext({});

const App: React.FC = () => {
    let themeSettings;
    try {
        themeSettings = JSON.parse(localStorage.getItem("themeSettings")!);
    } catch {
        themeSettings = {};
    }

    return (
        <ThemeContext.Provider value={useState(themeSettings)}>
            <ConfigProvider theme={{algorithm: themeSettings?.globalTheme === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm}}>
                <ApolloProvider client={client}>
                    <LayoutComponent/>
                </ApolloProvider>
            </ConfigProvider>
        </ThemeContext.Provider>
    );
};

export default App;
