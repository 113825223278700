import React from "react";
import {Link, Outlet} from "react-router-dom";
import {Layout, Typography} from "antd";
import Nav from "./Nav";

export const LayoutComponent: React.FC = () => {

    return (
        <Layout style={{height: "100vh"}}>
            <Layout.Header style={{display: "flex", justifyContent: "space-between", paddingLeft: 10, paddingRight: 10, backgroundColor: "#001529"}}>

                <Link to="/" style={{display: "flex", alignItems: "center", textDecorationLine: "none", userSelect: "none"}}>
                    <img src="/assets/images/logo.png" className="me-2" alt="Brand Logo" width={40}/>
                    <Typography.Text strong id="headerTitle" style={{fontSize: 25, color: "white"}}>Desc Team - Quarteera</Typography.Text>
                </Link>

                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", cursor: "pointer"}}>
{/*
                        <Typography.Text strong style={{color: "white"}}>Leon San José Larsson</Typography.Text>
                        <Typography.Text style={{fontSize: "0.8rem", color: "white"}}>github.com/leonlarsson</Typography.Text>
*/}
                    </div>
                </div>

            </Layout.Header>

            <Layout>
                <Layout.Sider width="200px" collapsible breakpoint="lg" theme={"dark"} collapsedWidth={60}>
                    <Nav/>
                </Layout.Sider>

                <Layout.Content style={{overflow: "auto"}}>
                    <div className="container p-4">
                        <Outlet/>
                    </div>
                </Layout.Content>
            </Layout>

        </Layout>
    );
};

