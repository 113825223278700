import React, {useRef, useState} from 'react';
import Page from "./Page";
import {useQuery} from '@apollo/client';
import {SearchOutlined} from '@ant-design/icons';
import {Button, DatePicker, Input, InputRef, Space, Table, TableColumnType, Tag, TimeRangePickerProps, Typography} from 'antd';
import type {Dayjs} from 'dayjs';
import dayjs from 'dayjs';
import type {FilterDropdownProps} from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import {TransactionDetailsInline} from "../TransactionDetailsInline";
import {Filter, Status, Transaction, Vault} from "../../types/transaction";
import type {ExpandableConfig} from "rc-table/lib/interface";
import {clientDateFormat, formatDate, formatNumber, getPeerName, serverDateFormat} from "../../utils/transactionUtils";
import {GET_TRANSACTIONS} from "../../queries/transaction";

const {Column} = Table;
const {RangePicker} = DatePicker;

type DataIndex = keyof Transaction;

const statusColors = new Map<string, string>([
  ["unassign", "default"],
  ["pending", "default"],
  ["booked", "processing"],
  ["processing", "warning"],
  ["accounted", "success"],
  ["error", "error"],
  ["closed", "success"]
]);

const rangePresets: TimeRangePickerProps['presets'] = [
  {label: 'Last year', value: [dayjs().startOf('year').add(-1, 'y'), dayjs().endOf('year').add(-1, 'y')]},
  {label: 'Actual year', value: [dayjs().startOf('year'), dayjs()]},
  {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
  {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
];

declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;
const parseDateRange =
  (dates: RangeValue<Dayjs>, defaultFilter: Filter): Filter => (
    {
      startDate: dates?.[0]?.format(serverDateFormat) || defaultFilter.startDate,
      endDate: dates?.[1]?.format(serverDateFormat) || defaultFilter.endDate,
      accounts: [],
      sources: []
    });

export const Transactions: React.FC = () => {

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [expandRowKeys, setExpandRowKeys] = useState<readonly React.Key[]>([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filter, setFilter] = useState<Filter>(
    {
      startDate: dayjs().startOf('month').format(serverDateFormat),
      endDate: dayjs().endOf('month').format(serverDateFormat),
      accounts: [],
      sources: []
    });
  const searchInput = useRef<InputRef>(null);

  const {loading, error, data} = useQuery(GET_TRANSACTIONS, {
    variables: {filter: filter},
    /*pollInterval: 30000*/
  });


  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: string,
  ): boolean => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    return true;
  };

  const handleReset = (clearFilters: () => void): boolean => {
    clearFilters();
    setSearchText('');
    return true;
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<Transaction> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters) && handleSearch([''], confirm, dataIndex)}
            size="small"
            style={{width: 90}}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}}/>
    ),
    onFilter: (value, record: Transaction) =>
      record[dataIndex]?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) || false,
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const expandable: ExpandableConfig<Transaction> = {
    expandRowByClick: true,
    onExpand: (expanded, record) => setExpandRowKeys(expanded ? [record.id] : []),
    expandedRowRender: (record) => <TransactionDetailsInline transaction={record} filter={filter}/>,
    expandedRowKeys: expandRowKeys,
    rowExpandable: (record: Transaction) => record.status.type !== 'Not Expandable',
  };


  return (
    <Page title="Transactions" description="" divider={true}>
      <Table<Transaction>
        loading={loading}
        dataSource={data?.transactionsByFilter}
        rowKey="id"
        //rowSelection={rowSelection}
        pagination={{position: ["topRight", "bottomRight"], defaultPageSize: 50}}
        size="small"
        expandable={expandable}
        title={_ => <Space>
          <RangePicker
            presets={rangePresets}
            value={[dayjs(filter.startDate, serverDateFormat), dayjs(filter.endDate, serverDateFormat)]}
            onChange={(dates: RangeValue<Dayjs>, dateString: [string, string]) => setFilter(parseDateRange(dates, filter))}
            format={clientDateFormat}
          />
        </Space>}
      >
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          width={70}
          render={(status: Status) => (
            <Tag color={statusColors.get(status.name)} key={status.id}>
              {status.name.toUpperCase()}
            </Tag>
          )}
          sorter={(a: Transaction, b: Transaction) => a.status.name.localeCompare(b.status.name)}
        />
        <Column
          title="Date"
          dataIndex="valueDate"
          key="valueDate"
          width={100}
          render={(value: string) => (
            <Typography.Text>{formatDate(value)}</Typography.Text>
          )}
          sorter={(a: Transaction, b: Transaction) => a.valueDate.localeCompare(b.valueDate)}
        />
        <Column
          title="Name"
          dataIndex="creditorName"
          key="creditorName"
          width={350}
          {...getColumnSearchProps("creditorName")}
          render={(_: any, record: Transaction) => (
            <Highlighter
              highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={getPeerName(record)}
            />
          )}
          onFilter={(value, record: Transaction) =>
            (record.amountValue > 0 ? record.debtorName : record.creditorName)
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase())}

        />
        <Column
          title="Description"
          dataIndex="description"
          key="description"
          {...getColumnSearchProps("description")}
        />
        <Column
          title="Amount"
          dataIndex="amountValue"
          key="amountValue"
          width={100}
          className="align-right"
          render={(amount: number) => (
            <Typography.Text style={{color: amount > 0 ? "green" : "red"}}>{formatNumber(amount)}</Typography.Text>
          )}

        />
        <Column title="Currency" dataIndex="amountCurrency" key="amountCurrency"/>
        <Column
          title="Reference"
          dataIndex="endToEndId"
          key="endToEndId"
          {...getColumnSearchProps("endToEndId")}
        />
        <Column
          title="Vault"
          dataIndex="vault"
          key="vault"
          width={70}
          render={(vault: Vault) => (
                  <Typography.Text>{vault.name}</Typography.Text>
                )}
        />
      </Table>
    </Page>
  );
};