import type {PresetStatusColorType} from "antd/es/_util/colors";
import {Journal, Transaction} from "../types/transaction";

export const serverDateFormat = "YYYY-MM-DD"
export const clientDateFormat = "DD.MM.YYYY"

export const formatNumber = (num: number): string => {
  let formattedNum = num.toFixed(2);
  formattedNum = formattedNum.replace('.', ',');
  return formattedNum.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const formatDate = (inputDate: string): string => {
  const parts = inputDate.split("-");
  return `${parts[2]}.${parts[1]}.${parts[0]}`;
};

export const formatIBAN = (iban: string): string => iban.replace(/(.{4})/g, '$1 ').trim();

export const getStatusBadge = (statusType: string): PresetStatusColorType => {
  switch (statusType) {
    case 'NEW':
      return 'default';
    case 'TODO':
      return 'default';
    case 'IN_PROGRESS':
      return 'processing';
    case 'DONE':
      return 'success';
    case 'ERROR':
      return 'error';
    default:
      return 'warning';
  }
};

export const getPeerName = (transaction: Transaction) => transaction.amountValue > 0 ? transaction.debtorName : transaction.creditorName;

export const upsertJournal = (existing: any[], newItem: any): any[] => {
  //console.log("existing: ", existing)
  //console.log("newItem: ", newItem)
  const foundIndex = existing.findIndex(item => item.id === newItem.id);
  if (foundIndex !== -1) {
    return [
      ...existing.slice(0, foundIndex),
      newItem,
      ...existing.slice(foundIndex + 1)
    ];
  } else {
    return [...existing, newItem];
  }
};

export const getSumJournals = (journals: readonly Journal[]) => journals?.reduce((previousValue: number, currentValue: Journal) => previousValue + currentValue.amountValue, 0) || 0;