import {gql} from "@apollo/client";

export const TRANSACTION = gql`
    fragment TransactionFragment on Transaction {
        id
        description
        bookingDate
        valueDate
        endToEndId
        amountCurrency
        amountValue
        creditorName
        creditorBic
        creditorIban
        debtorName
        debtorBic
        debtorIban
        mandateId
        vault {
            id
            name
        }
        status {
            id
            name
            type
        }
    }
`;

export const JOURNAL = gql`
    fragment JournalFragment on Journal {
        id
        peerName
        description
        valueDate
        amountValue
        amountCurrency
        account {
            id
            name
            fullName
            description
        }
        source {
            id
            key
            name
            description
        }
    }
`;

export const GET_TRANSACTION = gql`
    ${TRANSACTION}
    query getTransactionsByFilter($id: ID) {
        transactionById (
            id: $id
        ) {
            ...TransactionFragment
        }
    }
`;
export const GET_TRANSACTIONS = gql`
    ${TRANSACTION}
    query getTransactionsByFilter($filter: TransactionFilter) {
        transactionsByFilter (
            filter: $filter
        ) {
            ...TransactionFragment
        }
    }
`;

export const GET_JOURNAL_BY_FILTER = gql`
    ${TRANSACTION}
    ${JOURNAL}
    query getJournalsByFilter($filter: JournalFilter) {
        journalsByFilter (
            filter: $filter
        ) {
            ...JournalFragment
            transaction {
                ...TransactionFragment
            }
        }
    }
`;

export const GET_JOURNALS = gql`
    ${JOURNAL}
    query getJournals($transactionId: ID) {
        journals (
            transactionId: $transactionId
        ) {
            ...JournalFragment
        }
    }
`;

export const SET_JOURNALS = gql`
    ${JOURNAL}
    mutation createOrUpdateJournal($journal: JournalInput) {
        journal (
            journal: $journal
        ) {
            ...JournalFragment
        }
    }
`;

export const GET_ACCOUNTS = gql`
    query getAccounts {
        accounts  {
            id
            name
            fullName
            description
        }
    }
`;

export const GET_SOURCES = gql`
    query getSources {
        sources  {
            id
            key
            name
            description
        }
    }
`;

export const ADJUST_TRANSACTION = gql`
    ${JOURNAL}
    mutation adjustTransaction($id: ID) {
        adjustTransaction (
            id: $id
        ) {
            ...JournalFragment
        }
    }
`;
